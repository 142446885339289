import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getThemeCollection,
  createThemeCollection,
  updateThemeCollection,
  getLabCustomThemeProducts,
  getCustomThemeProductPreview,
  getStudioThemeCollections
} from '../actions';

// Plugins
import Select from 'react-select';
import { isEqual } from 'lodash';
import Dropzone from 'react-dropzone';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

// Components
import PreviewOverlay from '../PreviewOverlay';

// Images
import image_preview_sample_MM_V from '@/assets/images/image-preview-sample-mm-vert.png';
import image_preview_sample_MM_HZ from '@/assets/images/image-preview-sample-mm-hrz.png';

const CollectionManager = ({ showCollectionManager, selectCollection, tableFilter, onManageThemeCancel }) => {
  const dropzoneRef = useRef();

  const dispatch = useDispatch();

  const { collection, customThemeLabs, customThemeProducts, previewList, requesting } = useSelector((state) => state.customThemes);
  const { studio } = useSelector((state) => state.login);

  const [labOptions, setLabOptions] = useState([]);
  const [styleOptions, setStyleOptions] = useState([]);
  const [supportedMimeTypes, setSupportedMimeTypes] = useState(['image/jpeg']);

  const [productOptions, setProductOptions] = useState([]);

  const [collectionInput, setCollectionInput] = useState('');
  const [labInput, setLabInput] = useState('');
  const [productInput, setProductInput] = useState('');
  const [styleInput, setStyleInput] = useState('');

  const [labSelected, setLabSelected] = useState('');
  const [productSelected, setProductSelected] = useState('');
  const [styleSelected, setStyleSelected] = useState({});

  const [initialThemesAdded, setInitialThemesAdded] = useState('');
  const [themesAdded, setThemesAdded] = useState([]);
  const [themeSelected, setThemeSelected] = useState({});
  const [themeReplace, setThemeReplace] = useState('');

  const [currentCollectionId, setCurrentCollectionId] = useState('');
  const [isCollectionReadOnly, setIsCollectionReadOnly] = useState(false);
  const [dropzoneIsActive, setDropZoneIsActive] = useState(false);
  const [collectionEditShow, setCollectionEditShow] = useState(false);
  const [confirmUpdateShow, setConfirmUpdateShow] = useState(false);
  const [dropRejected, setDropRejected] = useState(false);
  const [uploadDimensionsError, setUploadDimensionsError] = useState(false);

  const [logoEnabled, setLogoEnabled] = useState(false);
  const [logoShow, setLogoShow] = useState(false);
  const [zoomShow, setZoomShow] = useState(false);

  const styleDefaults = [
    { value: 'white_no_stroke', label: 'White Text, No Stroke' },
    { value: 'white_with_stroke', label: 'White Text, With Stroke' },
    { value: 'black_no_stroke', label: 'Black Text, No Stroke' },
    { value: 'black_with_stroke', label: 'Black Text, With Stroke' }
  ];

  const loadProductOptions = (products) => {
    const productsList = products.map((product) => ({
      value: product.id,
      label: product.name,
      layoutId: product.theme_layouts[0].id,
      themeLayouts: product.theme_layouts,
      expectedWidth: product.theme_layouts[0].theme_graphic_width,
      expectedHeight: product.theme_layouts[0].theme_graphic_height
    }));

    setProductOptions(productsList);
  };

  const loadLabOptions = (labs) => {
    const options = labs.map((lab) => ({ value: lab.id, label: lab.name }));

    setLabOptions(options);
  };

  const handleThemeManagerCancel = () => {
    setCollectionInput('');
    setProductOptions([]);
    setProductInput('');
    setStyleInput('');
    setStyleSelected({});
    setLabSelected('');
    setProductSelected('');
    setThemesAdded([]);
    setThemeSelected({});
    setThemeReplace('');
    setCollectionEditShow(false);
    setCurrentCollectionId('');
    setConfirmUpdateShow(false);
    setDropRejected(false);
    setIsCollectionReadOnly(false);
    setInitialThemesAdded('');
    onManageThemeCancel();
  };

  const handleCollectionInputChange = (e) => {
    setCollectionInput(e.target.value);
  };

  const handleLabInputChange = (input) => {
    setLabInput(input);
  };

  const handleProductInputChange = (input) => {
    setProductInput(input);
  };

  const handleStyleInputChange = (input) => {
    setStyleInput(input);
  };

  const handleLabChange = (input) => {
    if (input.value !== productSelected?.value) {
      setLabSelected(input);
      setLabInput('');
    }
  };

  const handleProductChange = (input) => {
    if (input.value !== productSelected?.value) {
      setProductSelected(input);
      setStyleSelected({});
      setThemeSelected({});

      const previewProduct = previewList.findIndex((item) => item.layout_id === input.layoutId);

      if (previewProduct === -1) {
        dispatch(
          getCustomThemeProductPreview({ layoutId: input.layoutId }, ({ data }) => {
            setLogoEnabled(data.nodes.some((node) => node.logo));
          })
        );
      } else {
        setLogoEnabled(previewList[previewProduct].nodes.some((node) => node.logo));
      }
    }
  };

  const handleStyleChange = (input) => {
    const newthemesAdded = themesAdded.map((theme) => {
      if (theme.uuid === themeSelected.uuid) {
        theme.layoutThemeAttribute.layout_node_graphics_attributes[0].font_style = input.value;
      }
      return theme;
    });

    setThemesAdded(newthemesAdded);
    setStyleSelected(input);
  };

  const handleSaveThumbnail = (thumbnail) => {
    const newthemesAdded = themesAdded.map((theme) => {
      if (theme.uuid === themeSelected.uuid) {
        const initialThemeAdded = JSON.parse(initialThemesAdded).find((initialTheme) => initialTheme.uuid === theme.uuid);

        // Don't update thumbnail if nothing has changed
        if (
          initialThemeAdded &&
          isEqual(theme.layoutThemeAttribute.layout_node_graphics_attributes, initialThemeAdded.layoutThemeAttribute.layout_node_graphics_attributes) &&
          theme.layoutThemeAttribute.show_logo === initialThemeAdded.layoutThemeAttribute.show_logo
        ) {
          return theme;
        } else {
          theme.layoutThemeAttribute.image_attachment = {
            content: thumbnail.src,
            filename: `theme_thumbnail_${themeSelected.uuid}.jpg`,
            content_type: 'image/jpeg'
          };
        }
      }

      return theme;
    });

    setThemesAdded(newthemesAdded);
  };

  const handleSwitchChange = (e) => {
    const shouldDisableLogo = e.target.checked ? true : false;
    const showLogo = shouldDisableLogo ? false : true;

    const newthemesAdded = themesAdded.map((theme) => {
      if (theme.uuid === themeSelected.uuid) {
        theme.layoutThemeAttribute.show_logo = showLogo;
      }

      return theme;
    });

    setLogoShow(showLogo);
    setThemesAdded(newthemesAdded);
  };

  const handleSelectTheme = (theme) => {
    const showLogo = theme.layoutThemeAttribute.show_logo;

    setLogoShow(showLogo);
    setThemeSelected(theme);
  };

  const handleFileDialogCancel = () => {
    // temporary fix: Dropzone bug may call dialogCancel accidentally with larger file sizes
    if (themeReplace) {
      setTimeout(() => setThemeReplace(''), 2000);
    }
  };

  const handleDropAccepted = (acceptedFiles) => {
    const image = acceptedFiles[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const currentProduct = customThemeProducts.find((product) => product.id === productSelected?.value);
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const uploadWidth = image['width'];
          const uploadHeight = image['height'];

          if (productSelected.expectedWidth !== uploadWidth || productSelected.expectedHeight !== uploadHeight) {
            setUploadDimensionsError(true);
            handleDropRejected();
            return null;
          }

          if (currentProduct) {
            const newTheme = {
              productId: currentProduct.id,
              uuid: Date.now(),
              layoutThemeAttribute: {
                lab_id: currentProduct.lab_id,
                layout_id: currentProduct.theme_layouts[0].id,
                name: collection.name,
                layout_node_graphics_attributes: [
                  {
                    layout_node_id: currentProduct.theme_layouts[0].layout_node_id,
                    font_style: styleOptions.length > 0 ? styleOptions[0].value : 'white_no_stroke',
                    image_attachment: {
                      content: image.src,
                      filename: name,
                      content_type: type
                    }
                  }
                ]
              }
            };

            if (themeReplace) {
              const newThemesAdded = themesAdded.map((themeAdded) => {
                if (
                  themeAdded &&
                  themeAdded.layoutThemeAttribute &&
                  themeAdded.layoutThemeAttribute.layout_node_graphics_attributes &&
                  themeAdded.uuid === themeReplace
                ) {
                  themeAdded.layoutThemeAttribute.layout_node_graphics_attributes[0].image_attachment =
                    newTheme.layoutThemeAttribute.layout_node_graphics_attributes[0].image_attachment;
                }
                return themeAdded;
              });

              setThemesAdded(newThemesAdded);
              setThemeReplace('');
            } else {
              setThemeSelected(newTheme);
              setStyleSelected(styleOptions[0]);
              setThemesAdded([...themesAdded, newTheme]);
            }
          }
        };
      });

      reader.readAsDataURL(image);
    }
  };

  const handleDropRejected = () => {
    handleDropRejectedToggle();
    setDropZoneIsActive(false);

    if (themeReplace) {
      setThemeReplace('');
    }
  };

  const handleDropRejectedToggle = () => {
    setDropRejected(!dropRejected);
    if (dropRejected) {
      setUploadDimensionsError(false);
    }
  };

  const handleDragEnter = () => {
    setDropZoneIsActive(true);
    setThemeSelected({});
  };

  const handleDragLeave = () => {
    setDropZoneIsActive(false);
  };

  const handleDialogOpen = () => {
    if (dropzoneRef.current && typeof dropzoneRef.current.open === 'function') {
      dropzoneRef.current.open();

      if (!themeReplace) {
        setThemeSelected({});
      }
    }
  };

  const handleReplaceTheme = (e, theme) => {
    e.stopPropagation();
    setThemeReplace(theme.uuid);
    handleSelectTheme(theme);
  };

  const handleRemoveTheme = (e, theme) => {
    e.stopPropagation();

    const newThemesAdded = themesAdded.map((themeAdded) => {
      if (themeAdded.uuid === theme.uuid) {
        themeAdded.layoutThemeAttribute = { id: theme.layoutThemeAttribute.id, _destroy: true };
      }
      return themeAdded;
    });

    setThemeSelected({});
    setThemesAdded(newThemesAdded);
  };

  const handleToggleConfirmShow = () => {
    setConfirmUpdateShow(!confirmUpdateShow);
  };

  const handleCreateCollection = () => {
    dispatch(
      createThemeCollection({ lab_id: labSelected?.value, studio_id: studio.id, name: collectionInput }, () => {
        dispatch(getStudioThemeCollections(tableFilter));
        setCollectionEditShow(false);
      })
    );
  };

  const handleUpdateCollection = () => {
    const productIds = [...new Set(themesAdded.map((theme) => theme.productId))];
    const layoutThemeAttributes = themesAdded.map((theme) => {
      const initialThemeAdded = JSON.parse(initialThemesAdded).find((initialTheme) => initialTheme.uuid === theme.uuid);

      if (
        initialThemeAdded &&
        isEqual(theme.layoutThemeAttribute.layout_node_graphics_attributes, initialThemeAdded.layoutThemeAttribute.layout_node_graphics_attributes) &&
        theme.layoutThemeAttribute.show_logo === initialThemeAdded.layoutThemeAttribute.show_logo
      ) {
        delete theme.layoutThemeAttribute.image_attachment;
      }

      return theme.layoutThemeAttribute;
    });

    dispatch(
      updateThemeCollection({ collectionId: collection.id, product_ids: productIds, layout_themes_attributes: layoutThemeAttributes }, ({ status }) => {
        // Deal with server errors
        if (status === 200) {
          handleThemeManagerCancel();
        }

        handleToggleConfirmShow();
      })
    );
  };

  const handleUpdateCollectionName = () => {
    if (collection.name !== collectionInput) {
      dispatch(
        updateThemeCollection({ collectionId: collection.id, name: collectionInput }, () => {
          setCollectionInput('');
          setCollectionEditShow(false);
        })
      );
    } else {
      setCollectionEditShow(false);
    }
  };

  const handleEditCollectionNameShow = () => {
    setCollectionEditShow(true);
    setCollectionInput(collection.name);
  };

  const handleToggleZoom = (e) => {
    e.stopPropagation();

    setZoomShow(!zoomShow);
  };

  const loadExistingLayoutThemes = (collection, products) => {
    const layoutThemes = [];

    collection.layout_themes?.forEach((layout) => {
      const currentProduct = products.find((product) => product.theme_layouts[0].id === layout.layout_id);

      if (currentProduct) {
        layoutThemes.push({
          productId: currentProduct.id,
          uuid: Date.now() + layoutThemes.length,
          currentThumbnail: layout.image_url,
          layoutThemeAttribute: {
            id: layout.id,
            lab_id: currentProduct.lab_id,
            layout_id: currentProduct.theme_layouts[0].id,
            name: collection.name,
            show_logo: layout.show_logo,
            layout_node_graphics_attributes: layout.layout_node_graphics
          }
        });
      }
    });

    setThemesAdded(layoutThemes);
    setInitialThemesAdded(JSON.stringify(layoutThemes));
  };

  const DropRejected = () => (
    <div className="flex center middle nowrap column animate collection-manager__themes-overlay">
      <h3>File not accepted</h3>
      {uploadDimensionsError ? (
        <p>
          Invalid dimensions! This product requires a width of <span className="text--bold">{productSelected.expectedWidth}px</span> and height of{' '}
          <span className="text--bold">{productSelected.expectedHeight}px</span>.
        </p>
      ) : (
        <p>{supportedTypes`We accept designs in ${supportedMimeTypes} format that are 15MB or less.`}</p>
      )}

      <br></br>
      <button className="button button--outline" type="button" onClick={handleDropRejectedToggle}>
        Continue
      </button>
    </div>
  );

  const handleProductLabel = (option) => {
    const themeCount = themesAdded.filter((theme) => theme.productId === option.value).length;
    const label = `${option.label} ${themeCount ? '(' + themeCount + ' themes)' : ''}`;

    return label;
  };

  const supportedTypes = (strings, types) => {
    let typesString = '';

    types.forEach((type, index) => {
      typesString += type.replace(/^.*\//gi, '').toUpperCase();

      if (index < types.length - 2) typesString += ', ';
      if (index === types.length - 2) typesString += ', and ';
    });

    return `${strings[0]} ${typesString} ${strings[1]}`;
  };

  const setupStyleOptions = () => {
    const customThemeLab = customThemeLabs?.find((lab) => lab.name === collection?.lab?.name);
    const customThemeLabFontStyles = customThemeLab?.themes_font_styles;

    const customThemeProduct = productSelected;
    const customThemeProductFontStyles = customThemeProduct?.themeLayouts ? customThemeProduct?.themeLayouts[0]?.themes_font_styles : [];

    const themeFontStyles = customThemeProductFontStyles?.length > 0 ? customThemeProductFontStyles : customThemeLabFontStyles;

    if (themeFontStyles?.length) {
      const styleDefaultsFiltered = [...styleDefaults].filter((style) => themeFontStyles.includes(style.value));
      setStyleOptions(styleDefaultsFiltered);
      setSupportedMimeTypes(customThemeLab?.custom_themes_mimetypes);
    } else {
      setStyleOptions([...styleDefaults]);
    }
  };

  const setupFontStyleOption = () => {
    const fontStyle = themeSelected.layoutThemeAttribute?.layout_node_graphics_attributes[0]?.font_style;

    if (fontStyle) {
      const style = styleOptions.find((style) => style.value === fontStyle);
      setStyleSelected(style || styleOptions[0]);
    }
  };

  useEffect(() => {
    if (showCollectionManager && selectCollection) {
      dispatch(
        getThemeCollection({ collectionId: selectCollection }, ({ data: collectionData }) => {
          setLabSelected({ value: collectionData.lab.id, label: collectionData.lab.name });
          setCollectionEditShow(false);
          setCollectionInput(collectionData.name);
          setIsCollectionReadOnly(collectionData.studio_id ? false : true);
        })
      );
    }

    if (showCollectionManager && !selectCollection) {
      setCollectionEditShow(true);
      loadLabOptions(customThemeLabs);
    }
  }, [selectCollection, showCollectionManager]);

  useEffect(() => {
    if (collection.id && collection.id !== currentCollectionId) {
      setCurrentCollectionId(collection.id);

      dispatch(
        getLabCustomThemeProducts({ id: collection.lab.id, customizable: true }, ({ data: productsData }) => {
          loadProductOptions(productsData);
          loadExistingLayoutThemes(collection, productsData);
        })
      );
    }
  }, [collection]);

  useEffect(() => {
    if (themeReplace) {
      handleDialogOpen();
    }
  }, [themeReplace]);

  useEffect(() => {
    if (!productSelected && productOptions.length > 0 && initialThemesAdded) {
      const productHasThemes = productOptions.find((option) => themesAdded?.some((theme) => theme.productId === option.value));
      // On Load select first product available that has themes added
      if (productHasThemes) {
        handleProductChange(productHasThemes);
      } else {
        handleProductChange(productOptions[0]);
      }
    }
  }, [productOptions, themesAdded, initialThemesAdded]);

  useEffect(() => {
    if (productSelected && !themeSelected.uuid) {
      const productThemes = themesAdded.filter((added) => added.productId === productSelected?.value && !added.layoutThemeAttribute._destroy);

      if (productThemes.length > 0) {
        // On product change select first theme available
        handleSelectTheme(productThemes[0]);
      }
    }
  }, [productSelected, themesAdded]);

  useEffect(() => {
    // Setup new theme selected style options first
    setupStyleOptions();
  }, [themeSelected]);

  useEffect(() => {
    // Select font style for that theme
    setupFontStyleOption();
  }, [styleOptions]);

  return (
    <div className={`modal ${showCollectionManager ? '' : 'transparent'} collection-manager`}>
      <div className="modal__box modal__box--secondary modal__box--xlarge">
        <button className="button button--action modal__close" name="button" type="button" disabled={confirmUpdateShow} onClick={handleThemeManagerCancel}>
          <i className="icon-close"></i>
        </button>
        <main className="collection-manager__content">
          {/* Customize Section Left */}
          {collectionEditShow ? (
            <section className="flex-5 flex-12-md column nowrap animate collection-manager__collection">
              <h3 className="text-left">{collection.id ? 'Edit Collection Name' : 'Add Theme Collection'}</h3>

              <input
                className="input--block mb-10"
                type="text"
                name="collection"
                value={collectionInput || ''}
                onChange={handleCollectionInputChange}
                maxLength="50"
                placeholder="Collection Name"
                required
              />

              <Select
                className="select"
                classNamePrefix="select"
                value={labSelected}
                options={labOptions}
                inputValue={labInput}
                onInputChange={handleLabInputChange}
                onChange={handleLabChange}
                isDisabled={currentCollectionId}
                placeholder="Select a lab"
              />

              <div className="flex center collection-manager__collection-footer">
                <button
                  className="button button--medium"
                  type="button"
                  onClick={currentCollectionId ? handleUpdateCollectionName : handleCreateCollection}
                  disabled={(!currentCollectionId && (!labSelected || !collectionInput)) || requesting}
                  data-loading={requesting}
                >
                  {selectCollection ? 'Update' : 'Next'}
                </button>
              </div>
            </section>
          ) : (
            <section className="flex-5 flex-12-md column animate collection-manager__customize">
              <div className="flex top nowrap animate collection-manager__customize-header">
                {!isCollectionReadOnly && (
                  <button className="button button--outline button--noborder" type="button" onClick={handleEditCollectionNameShow}>
                    <i className="icon-back"></i>
                  </button>
                )}

                <h3 className="text-left">{collection.name}</h3>
              </div>
              <div className="flex column nowrap">
                <Select
                  className="select"
                  classNamePrefix="select"
                  isMulti={false}
                  value={productSelected}
                  options={productOptions}
                  getOptionLabel={handleProductLabel}
                  inputValue={productInput}
                  onInputChange={handleProductInputChange}
                  onChange={handleProductChange}
                  placeholder="Select product to customize"
                />

                {productSelected?.value && (
                  <>
                    <Dropzone
                      ref={dropzoneRef}
                      disabled={isCollectionReadOnly}
                      multiple={false}
                      maxSize={15728640}
                      accept={{ 'image/*': supportedMimeTypes.map((type) => (type.includes('png') ? '.png' : ['.jpeg', '.jpg'])).flat() }}
                      noClick={true}
                      noKeyboard={true}
                      noDragEventsBubbling={true}
                      onFileDialogCancel={handleFileDialogCancel}
                      preventDropOnDocument={true}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDropAccepted={handleDropAccepted}
                      onDropRejected={handleDropRejected}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          {themesAdded.filter((added) => added.productId === productSelected?.value && !added.layoutThemeAttribute._destroy).length ? (
                            <>
                              <div
                                className={`flex column between nowrap collection-manager__themes-container ${
                                  dropzoneIsActive ? 'collection-manager__themes-container--active' : ''
                                }`}
                              >
                                <div className="collection-manager__themes" {...getRootProps()}>
                                  {themesAdded
                                    .filter((added) => added.productId === productSelected?.value && !added.layoutThemeAttribute._destroy)
                                    .map((theme, index) => {
                                      const currentThemeImage = theme.layoutThemeAttribute.layout_node_graphics_attributes[0].image_url;
                                      const themeImage = currentThemeImage || theme.layoutThemeAttribute.layout_node_graphics_attributes[0].image_attachment;
                                      const currentThumbnail = theme.currentThumbnail;
                                      const newThumbNail = theme.layoutThemeAttribute.image_attachment;

                                      return (
                                        <figure
                                          className={`flex nowrap between  center collection-manager__theme ${
                                            themeSelected.uuid === theme.uuid ? 'collection-manager__theme--active' : ''
                                          }`}
                                          key={index}
                                          onClick={() => handleSelectTheme(theme)}
                                        >
                                          <div className="flex middle center collection-manager__theme-image">
                                            <LazyLoadImage
                                              src={newThumbNail ? newThumbNail.content : currentThumbnail || themeImage.content}
                                              effect="opacity"
                                              alt={themeImage.filename}
                                            />
                                          </div>
                                          {!isCollectionReadOnly && (
                                            <figcaption className="flex nowrap between middle collection-manager__theme-actions">
                                              <button className="button button--outline text--bold" type="button" onClick={(e) => handleReplaceTheme(e, theme)}>
                                                Replace
                                              </button>
                                              <button className="button button--outline text--bold" type="button" onClick={(e) => handleRemoveTheme(e, theme)}>
                                                Remove
                                              </button>
                                            </figcaption>
                                          )}
                                        </figure>
                                      );
                                    })}
                                </div>
                                {!isCollectionReadOnly && (
                                  <small className="text--xsmall collection-manager__themes-footer">
                                    Drag additional designs to add or{' '}
                                    <button className="button button--link text--bold" onClick={handleDialogOpen}>
                                      upload here
                                    </button>
                                  </small>
                                )}

                                {dropRejected && <DropRejected />}
                              </div>
                            </>
                          ) : (
                            <div
                              className={`flex column middle collection-manager__themes-container ${
                                dropzoneIsActive ? 'collection-manager__themes-container--active' : ''
                              }`}
                            >
                              <div className="flex middle center collection-manager__themes collection-manager__themes--padded" {...getRootProps()}>
                                <div className="flex column middle center">
                                  <i className="icon-upload-theme"></i>
                                  <br></br>
                                  <p>
                                    Drag theme{' '}
                                    <button className="button button--link text--bold" onClick={handleDialogOpen}>
                                      here
                                    </button>{' '}
                                    or upload a{' '}
                                    <button className="button button--link text--bold" onClick={handleDialogOpen}>
                                      file
                                    </button>
                                  </p>
                                  <small className="text--xsmall">{supportedTypes`We accept designs in ${supportedMimeTypes} format that are 15MP or less.`}</small>
                                </div>

                                {dropRejected && <DropRejected />}
                              </div>
                            </div>
                          )}

                          <input {...getInputProps({ disabled: false })} />
                        </>
                      )}
                    </Dropzone>

                    {!isCollectionReadOnly && (
                      <>
                        <Select
                          className="select collection-manager__theme-style"
                          classNamePrefix="select"
                          isMulti={false}
                          value={styleSelected}
                          options={styleOptions}
                          inputValue={styleInput}
                          onInputChange={handleStyleInputChange}
                          onChange={handleStyleChange}
                          isDisabled={!Object.keys(themeSelected).length}
                          placeholder="Select font style"
                        />

                        {logoEnabled && Object.keys(themeSelected).length > 0 && (
                          <>
                            <div className="flex between middle nowrap">
                              <span>Disable Logo</span>
                              <input
                                id="showLogo"
                                className="hidden"
                                name="showLogo"
                                type="checkbox"
                                checked={logoShow ? false : true}
                                onChange={handleSwitchChange}
                              />
                              <label className="label-switch label-switch--small" htmlFor="showLogo" />
                            </div>
                            <span className="text-left">
                              <small className="text--xsmall">
                                If disabled, a logo will never be shown on this <br /> theme regardless of job settings.
                              </small>
                            </span>
                          </>
                        )}
                      </>
                    )}

                    <div className="collection-manager__customize-footer">
                      {!isCollectionReadOnly && (
                        <small className="text--xsmall">
                          Need help? Read the{' '}
                          <a href="https://support.photoday.io/en/articles/4156983-custom-themes" target="_blank" rel="noopener noreferrer">
                            Custom Themes support article
                          </a>
                          .
                        </small>
                      )}
                    </div>
                  </>
                )}
              </div>
            </section>
          )}

          {/* Preview Section Right */}
          {currentCollectionId ? (
            <section className="flex-7 flex-12-md collection-manager__preview">
              <h3 className={`text--left ${collectionEditShow ? 'text--grey' : ''}`}>Preview Theme</h3>
              {productSelected?.value && Object.keys(themeSelected).length > 0 ? (
                <>
                  <div className={`collection-manager__preview-container ${collectionEditShow ? 'collection-manager__preview-container--disabled' : ''}`}>
                    {isCollectionReadOnly ? (
                      <figure className="collection-manager-preview__figure">
                        <img src={themeSelected.currentThumbnail} className="collection-manager-preview__not-found" alt="theme preview" />
                      </figure>
                    ) : (
                      <PreviewOverlay
                        previewList={previewList}
                        layoutId={productSelected.layoutId}
                        themeImage={
                          themeSelected.layoutThemeAttribute.layout_node_graphics_attributes[0].image_attachment
                            ? themeSelected.layoutThemeAttribute.layout_node_graphics_attributes[0].image_attachment.content
                            : themeSelected.layoutThemeAttribute.layout_node_graphics_attributes[0].image_url
                        }
                        styleSelected={styleSelected}
                        logoShow={logoShow}
                        productSelected={productSelected}
                        onSaveThumbnail={handleSaveThumbnail}
                      />
                    )}

                    <button className="button button--link button--center" onClick={handleToggleZoom}>
                      View Larger
                    </button>
                  </div>

                  <br />
                  <small className="text--grey text--block text--center text--xsmall">This is a rendering of what your custom theme may look like.</small>
                </>
              ) : (
                <figure className="flex center">
                  <figcaption className="flex middle center collection-manager__preview-container">
                    {productSelected?.value ? (
                      <img
                        src={productSelected.label.toLowerCase().includes('vertical') ? image_preview_sample_MM_V : image_preview_sample_MM_HZ}
                        className="collection-manager__preview-sample"
                        alt="sample layout"
                      />
                    ) : (
                      <small className="text--grey">Please customize a product on the left to see its preview.</small>
                    )}
                  </figcaption>
                </figure>
              )}

              <div className="collection-manager__preview-footer">
                <button
                  className="button button--center button--medium"
                  name="button"
                  type="submit"
                  disabled={requesting || collectionEditShow || themesAdded.length === 0}
                  data-loading-dark={requesting}
                  onClick={isCollectionReadOnly ? handleThemeManagerCancel : handleToggleConfirmShow}
                >
                  Done
                </button>
              </div>
            </section>
          ) : (
            <section className="flex-7 flex-12-md collection-manager__preview">
              <h3 className="text--left text--grey">Preview Theme</h3>
              <figure className="flex center">
                <figcaption className="collection-manager__preview-container">
                  <small className="text--grey">You’ll need to add a theme collection before you can customize a product’s theme.</small>
                </figcaption>
              </figure>
            </section>
          )}

          {/* Zoom  */}
          {currentCollectionId && zoomShow && (
            <aside className={`flex column middle center modal__content collection-manager__zoom-overlay animate ${zoomShow ? '' : 'transparent'}`}>
              <button className="button button--action modal__close" name="button" type="button" onClick={(e) => handleToggleZoom(e)}>
                <i className="icon-close"></i>
              </button>
              <div className="collection-manager__zoom-preview">
                <img
                  src={
                    themeSelected.layoutThemeAttribute.image_attachment
                      ? themeSelected.layoutThemeAttribute.image_attachment.content
                      : themeSelected.currentThumbnail
                  }
                  alt="preview theme"
                ></img>
              </div>
            </aside>
          )}

          {/* Confirm Update */}
          <aside className={`flex column middle center modal__content collection-manager__confirm  ${confirmUpdateShow ? '' : 'transparent'}`}>
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={handleToggleConfirmShow}>
                <i className="icon-close"></i>
              </button>
            </header>
            <div className="collection-manager__confirm-content">
              <p>Confirming will publish any changes or new themes you may have added and will be immediately live for customers to see.</p>
              <button className="button button--medium" type="button" disabled={requesting} data-loading-dark={requesting} onClick={handleUpdateCollection}>
                Confirm
              </button>
            </div>
          </aside>
        </main>
      </div>
    </div>
  );
};

CollectionManager.propTypes = {
  selectCollection: PropTypes.string.isRequired,
  showCollectionManager: PropTypes.bool.isRequired,
  onManageThemeCancel: PropTypes.func.isRequired
};

CollectionManager.defaultProps = {
  selectCollection: '',
  showCollectionManager: false
};

export default CollectionManager;
